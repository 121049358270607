<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link to="/" class="brand-logo d-flex align-center">
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3"
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="
                require(`@/assets/images/misc/mask-v2-${
                  $vuetify.theme.dark ? 'dark' : 'light'
                }.png`)
              "
            />

            <!-- tree -->
            <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-4.png"
            ></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="
                  require(`@/assets/images/3d-characters/group-${
                    $vuetify.theme.dark ? 'dark' : 'light'
                  }.png`)
                "
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Bienvenue 👋🏻
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form @submit.prevent="handleLogin">
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="email"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Mot de passe"
                      placeholder="Mot de passe"
                      :append-icon="
                        isPasswordVisible
                          ? icons.mdiEyeOffOutline
                          : icons.mdiEyeOutline
                      "
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <v-btn block color="primary" type="submit" class="mt-6">
                      Connexion
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from "@mdi/js";
import { getCurrentInstance, ref } from "@vue/composition-api";
import themeConfig from "@themeConfig";
import axios from "@axios";
import { useRouter } from "@core/utils";

export default {
  setup() {
    const vm = getCurrentInstance().proxy;
    const { router } = useRouter();
    const isPasswordVisible = ref(false);

    const email = ref("");
    const password = ref("");

    const socialLink = [
      {
        icon: mdiFacebook,
        color: "#4267b2",
        colorInDark: "#4267b2",
      },
      {
        icon: mdiTwitter,
        color: "#1da1f2",
        colorInDark: "#1da1f2",
      },
      {
        icon: mdiGithub,
        color: "#272727",
        colorInDark: "#fff",
      },
      {
        icon: mdiGoogle,
        color: "#db4437",
        colorInDark: "#db4437",
      },
    ];

    const handleLogin = async () => {
      const bodyFormData = new FormData();
      bodyFormData.append("username", email.value);
      bodyFormData.append("password", password.value);
      const { data } = await axios.post("/users/login", bodyFormData);

      const {
        access_token,
        refresh_token,
        abilities,
        home_page_url,
        user_name,
        user_email,
      } = data;

      localStorage.setItem("accessToken", access_token);
      localStorage.setItem("refreshToken", refresh_token);
      localStorage.setItem("userAbility", JSON.stringify(abilities));

      vm.$ability.update(abilities);
      await vm.$store.dispatch("app/setHomePageURL", home_page_url);
      await vm.$store.dispatch("app/setUserName", user_name);
      await vm.$store.dispatch("app/setUserEmail", user_email);

      router.push(home_page_url || "/general/dashboard");
    };

    return {
      handleLogin,
      isPasswordVisible,
      email,
      password,
      socialLink,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/preset/preset/pages/auth.scss";
</style>
